//import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

export const useScrollFunctions = () => {


    const scrollToY = (scrollTargetY: number, scrollSpeed?: number, scrollEasing?: 'easeOutSine' | 'easeInOutSine' | 'easeInOutQuint', scrollTarget?: any, callback?: any) => {
        // scrollTargetY: the target scrollY property of the window
        // speed: time in pixels per second
        // easing: easing equation to use

        var element = scrollTarget || window

        var scrollY = element.scrollTop || window.scrollY || document.documentElement.scrollTop,
            scrollTargetY = scrollTargetY || 0,
            speed = scrollSpeed || 2000,
            easing = scrollEasing || 'easeOutSine',
            currentTime = 0;

        // min time .1, max time .8 seconds
        var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

        // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
        var easingEquations = {
            easeOutSine: function (pos: number) {
                return Math.sin(pos * (Math.PI / 2));
            },
            easeInOutSine: function (pos: number) {
                return (-0.5 * (Math.cos(Math.PI * pos) - 1));
            },
            easeInOutQuint: function (pos: number) {
                if ((pos /= 0.5) < 1) {
                    return 0.5 * Math.pow(pos, 5);
                }
                return 0.5 * (Math.pow((pos - 2), 5) + 2);
            }
        };

        // add animation loop
        function tick() {
            currentTime += 1 / 60;

            var p = currentTime / time;
            var t = easingEquations[easing](p);

            if (p < 1) {
                window.requestAnimationFrame(tick);
                element.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
            } else {
                element.scrollTo(0, scrollTargetY);
                if (callback) callback()
            }
        }

        // call it once to get started
        tick();
    }
    const timeToY = (scrollTargetY: number, scrollDuration: number) => {
        const startPos = window.pageYOffset;
        const diff = scrollTargetY - startPos;

        let startTime = <number | null>null;
        let requestId: any;

        const loop = function (currentTime: number) {
            if (!startTime) {
                startTime = currentTime;
            }

            // Elapsed time in miliseconds
            const time = currentTime - startTime;

            const percent = Math.min(time / scrollDuration, 1);
            window.scrollTo(0, startPos + diff * percent);

            if (time < scrollDuration) {
                // Continue moving
                requestId = window.requestAnimationFrame(loop);
            } else {
                window.cancelAnimationFrame(requestId);
            }
        };
        requestId = window.requestAnimationFrame(loop);
    }
    const getYPosition = (el: HTMLElement) => {
        return window.pageYOffset + el.getBoundingClientRect().top
    }
    const getScrollTop = () => {
        return window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
    }

    return {
        scrollToY,
        getYPosition,
        getScrollTop,

        timeToY

        //enableBodyScroll,
        //disableBodyScroll
    }
}